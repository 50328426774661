<template>
  <div class="hosted-pg">
    <!-- Payment Request Template List card starts here -->

    <vs-card class="mt-5">
      <div slot="header">
        <div class="flex justify-between items-center">
          <h3 class="text-2xl font-normal">Default communications settings</h3>
        </div>
      </div>
      <div>
        <h3 class="text-xl font-normal">Communications sender</h3>
        <hr class="shadow-line-hr my-3" />
        <p class="mt-5 mb-8 font-normal">
          Set the sender name you want customers to see when they receive email communications about payment requests.
        </p>
        <div class="">
          <div class="flex flex-wrap">
            <div class="textarea-input input-grp mb-8">
              <label class="w-full">Email sender name <span>*</span></label>
              <vs-textarea
                counter="25"
                maxlength="25"
                name="emailSender"
                v-model="emailSender"
                v-validate="'required|max:25'"
                data-vv-as="Email sender name"
                id="emailSender"
              ></vs-textarea>
              <span
                class="text-danger text-sm"
                v-show="errors.has('emailSender')"
                >{{ errors.first("emailSender") }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3 class="text-xl font-normal">Default contact details</h3>
        <hr class="shadow-line-hr my-3" />
        <p class="mt-5 mb-8 font-normal">
          Set the contact details you want customers to see in the body of
          emails and SMS messages they receive.
        </p>
        <div>
          <div class="mb-6">
            <div class="input-grp">
              <label class="w-full">Name</label>
              <vs-input
                class="w-full"
                v-validate="'required|max:100'"
                size="large"
                v-model="fullName"
                data-vv-as="Name"
                name="fullName"
                id="fullName"
              />
              <span
                class="text-danger text-sm mt-5"
                v-show="errors.has('fullName')"
                >{{ errors.first("fullName") }}</span
              >
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-wrap mb-6">
            <div class="input-grp">
              <label class="w-full">Email address</label>
              <vs-input
                class="w-full"
                v-validate="'required|email|min:3'"
                size="large"
                v-model="emailAddress"
                name="emailAddress"
                id="emailAddress"
                data-vv-as="Email address"
              />
              <span
                class="text-danger text-sm mt-5"
                v-show="errors.has('emailAddress')"
                >{{ errors.first("emailAddress") }}</span
              >
            </div>
          </div>
          <div>
            <div class="input-grp">
              <label class="w-full">Phone number</label>
              <div class="flex flex-col">
                <the-mask
                  :class="
                    phoneNumber
                      ? 'vs-inputx vs-input--input large'
                      : 'vs-inputx vs-input--input large'
                  "
                  :mask="[checkPhoneNumber]"
                  v-model="phoneNumber"
                  masked
                  v-validate="'required|phoneFormat'"
                  data-vv-as="Phone number"
                  name="phoneNumber"
                  id="phoneNumber"
                  ref="phoneNumber"
                />

                <span
                  class="text-danger text-sm mt-5"
                  v-show="errors.has('phoneNumber')"
                  >{{ errors.first("phoneNumber") }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <leave-popup
        :popup-active="popupActive"
        @handleClick="popActions"
      ></leave-popup>

      <pop-up :errorsList="errors" form-name="CommunicationForm"></pop-up>
      <div class="button-section">
        <vs-button
          size="large"
          :disabled="!validateForm || !isFormDirty"
          @click="updateCommunicationSettings"
          v-round
          >Save</vs-button
        >
        <a class="cancel-btn mid-blue ml-8" @click="$router.go(-1)">Cancel</a>
      </div>
    </vs-card>
  </div>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { Validator } from "vee-validate";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";
import { mapActions } from "vuex";
const dictionary = {
  en: {
    attributes: {
      emailSender: "email sender",
      fullName: "full name",
      emailAddress: "email address",
      phoneNumber: "phone number",
    },
  },
};
Validator.localize(dictionary);

export default {
  mixins: [leavePopUpMixin],
  components: { TheMask, PopUp, LeavePopup },
  data() {
    return {
      saveDisabled: true,
      emailSender: process.env.VUE_APP_NAME,
      fullName: "",
      emailAddress: "",
      phoneNumber: "",
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      isSaved: false,
      nextObj: "", // determines the next route,
    };
  },
  methods: {
    ...mapActions("communicationGlobalSetting", ["fetchMerchantCommunicationSettingsByPartner", "updateMerchantCommunicationSettingsByPartner"]),
    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },
    getPartnerId() {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.userType === 'admin' ? user._id : user.partnerId;
    },
    getCommunicationSettings() {
      this.$vs.loading();
      this.fetchMerchantCommunicationSettingsByPartner(this.getPartnerId()).then((result) => {
          this.$vs.loading.close();
          if (result.data.data && result.data.data != null) {
            var senderDetails = result.data.data.requestTemplateCommunication;
            if (senderDetails) {
              this.emailSender = senderDetails.emailSender;
              this.fullName = senderDetails.fullname;
              this.emailAddress = senderDetails.senderEmail;
              this.phoneNumber = senderDetails.senderPhone;
              this.overrideDefault = senderDetails.allowOverride;
            }
          }
        }).catch((ex) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Notification",
            text: ex.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    async updateCommunicationSettings() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          const data = {
            id: this.getPartnerId(),
            payload: {
              emailSender: this.emailSender,
              fullname: this.fullName,
              senderEmail: this.emailAddress,
              senderPhone: this.phoneNumber,
              type: "PR",
            },
          };
          this.$vs.loading();
          this.updateMerchantCommunicationSettingsByPartner(data).then((result) => {
            this.$vs.loading.close();
            this.popupActive = false;
            this.$vs.notify({
              title: "Communication settings",
              text: result.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            this.isSaved = true;
            this.$router.push({ name: "settings" });
          }).catch((ex) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Communication settings",
              text: ex.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    //check here
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },

  watch: {
    isFormDirty(latest, previous) {
      if (latest) localStorage.setItem("formFieldUpdated", "yes");
    },

    isSaved(latest, previous) {
      if (!latest) localStorage.setItem("formFieldUpdated", "yes");
    }
  },

  beforeMount() {
    this.getCommunicationSettings();
  },
  created() {
    this.$validator.extend("phoneFormat", {
      getMessage: () => "The phone number format is invalid.",
      validate: (value) => value.match(/^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/) !== null,
    });
  },
  computed: {
    checkPhoneNumber() {
      let mask = "#### ### ###";
      if (this.phoneNumber && this.phoneNumber.length > 0) {
        let numLength = this.phoneNumber.length;
        let value1 = "";
        let value2 = "";
        if (numLength >= 2) {
          value1 = this.phoneNumber.substring(0, 2);
          if (value1 === "13") {
            value2 = this.phoneNumber.substring(0, 4);
          }
        }
        if (
          value1 === "02" ||
          value1 === "03" ||
          value1 === "07" ||
          value1 === "08"
        ) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === "13") {
          if (
            parseInt(value2) >= 1300 ||
            value2 === "130" ||
            value2 === "13 0"
          ) {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }
      return mask;
    },
  },
};
</script>